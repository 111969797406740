/**
 * secondary links
 */
.secondary-links {
  padding: 2.5rem 0;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid $grey-light;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-right: 6rem;
  }

  a {
    color: $black;
    text-decoration: none;
  }
}