/**
 * history-back.scss
 */
.history-back {
  display: inline-block;
  margin: 3rem 0;

  a {
    display: block;
    padding-left: 3.4rem;
    background: url('/img/arrow-prev.svg') no-repeat 0 50% / 2.3rem  auto;
  }
}