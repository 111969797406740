/**
 * forms
 */
.form {

  &-group {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 2rem;

    label {
      order: -1;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #ccc;
        transition: all linear .3s;
      }
    }

    .form-control {
      border: 0;
      font-size: 2rem;
      padding: 0.5rem 0;
      box-shadow: none;

      &:focus ~ label {
        color: $primary;

        &:before {
          background-color: $primary;
          box-shadow: 0 0 .6rem rgba($primary, 0.4);
        }
      }
    }
  }

  &-text {
    font-size: 1.2rem;
  }

}