/**
 * collapse card
 */
.collapse-card {
  border: 1px solid #D5DCEB;
  border-radius: 3.2rem;
  background: #F2F5FE;
  overflow: hidden;

  &__header {
    padding: 2.2rem;
  }

  &__body {
    padding: 2.2rem;
    background: $white;
    border-top: 1px solid #D5DCEB;
  }

  &__footer {
    padding: 1.5rem 2.2rem;
    background: $white;
    border-top: 1px solid #D5DCEB;
  }

  &__title {
    font-size: 2rem;
    line-height: 1.4;
    position: relative;
    padding-left: 3.4rem;
    cursor: pointer;

    &:before {
      position: absolute;
      left: 0;
      width: 12px;
      height: 6px;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      background: url('/img/arrow-down-grey.svg');
    }

    &[aria-expanded="true"]:before{
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__status {
    color: $black-02;

    &_complete {
      color: $green-01;
    }
  }

  &__duration {
    color: $black-02;
    min-width: 10rem;
    text-align: right;
  }

}