/**
 * layout-seminars.scss
 */
.seminars-tpl {

  .header {
    background: $blue-05;

    &__wrapper {
      margin: 0 auto;
      padding: 1rem 1.5rem;

      @media (max-width: 1365px) {
        padding: 1rem 3.2rem;
      }
    }

    &__navigation {

      a {
        color: $grey-02;
        &:hover {
          color: rgb(21, 53, 255);
        }
      }

      li:last-child {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
    }
  }

  .main {
    display: block;
    padding-top: 10rem;
  }

  .content {
    padding: 0 1.5rem;
    margin: auto;
  }

  .footer {

    &__wrapper {
      margin: 0 auto;
      padding: 0 1.5rem;
    }

    &__spoiler {
      display: none;
    }

    &__content {

      &-info {
        margin-left: 0;
        margin-right: 0;
      }

      &-address {
        flex: 0 0 34rem;
      }

    }

    &__copyright {
      padding-left: 0;
      padding-right: 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        top: -1px;
        border-top: 1px solid $grey-light;
      }

      &-info {
        padding-left: 0;
      }
    }
  }

  // breadcrumb
  .breadcrumb-item {
    a {
      color: $grey-04;
      text-decoration: underline;
    }

    +.breadcrumb-item {
      padding-left: 2rem;

      &:before {
        padding-right: 2rem;
        color: $grey-04;
      }
    }
  }
}

.mobile-filter {
  display: block;
  @media (min-width: 981px) {
    display: none;
  }
}

.seminars {
  &-filters {
    display: flex;
    flex-flow: wrap;
    @media (max-width: 980px) {
      display: block;
    }
  }

  &-data {

    &__filters {
      flex: 0 0 30rem;
      max-width: 30rem;
      @media (max-width: 980px) {
        display: none;
      }
    }

    &__content {
      flex: 0 0 calc(100% - 30rem);
      max-width: calc(100% - 30rem);
      padding-left: 2.4rem;
      @media (max-width: 980px) {
        max-width: 100%;
        padding-left: 0;
      }
    }
  }
}