// Variables Bootstrap 4

$red:                         #cc2eaa;
$green:                       #349C6A;
$primary:                     #6177FF;
$grey:                        #eee;
$grey-light:                  #D5DCEB;
$grey-middle:                 #7A7A7C;
$grey-dark:                   #555;
$grey-middle-dark:            #A4A4A7;
$black:                       #000000;
$white:                       #ffffff;
$bg:                          #E5E5E5;
$sidebar-bg:                  #344883;
$sidebar-section:             #2E386B;
$sidebar-section-border:      #8A98BD;

$black-02:                    #7A7A7C;
$black-03:                    #9C9D9E;

$green-01:                    #349C6A;

$red-01:                      #FF0000;

$grey-02:                     rgb(158, 158, 158);
$grey-04:                     #c4c4c4;

$blue-0:                      #344883;
$blue-01:                     #4151A2;
$blue-02:                     #6177FF;
$blue-04:                     #D5DCEB;
$blue-05:                     #F2F5FE;

$sidebar-bg:                  $blue-0;

$body-bg:                     #fff;
$font-family-sans-serif:      "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$body-color:                  #343a40;
$shadow-color:                #e0e4e7;
$shadow-color-dark:           #bdc0c2;
$border-radius:               6px;
$border-radius-big:           1.6rem;

// Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 769px,
    lg: 992px,
    xl: 1200px
) !default;

// Spacers
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
        (
            0: 0,
            1: ($spacer),
            2: ($spacer * 2),
            3: ($spacer * 3),
            4: ($spacer * 4),
            5: ($spacer * 5),
            6: ($spacer * 6)
        ),
        $spacers
);

// Cards
$card-spacer-y:                     0;
$card-spacer-x:                     0;
$card-border-width:                 0;
$card-border-radius:                $border-radius-big;
$card-border-color:                 transparent;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-bg:                       rgba($black, .03) !default;
$card-cap-color:                    null;
$card-height:                       null;
$card-color:                        null;
$card-bg:                           $white;
$card-shadow:                       0px 0px 20px rgba(195, 212, 219, 0.15);
$card-padding:                      3.5rem;

// Badges
$badge-font-size:                   1.2rem;
$badge-padding-y:                   .4rem;
$badge-padding-x:                   1rem;
$badge-border-radius:               0.2rem;
$badge-font-weight:                 600;

// Modal
// Padding applied to the modal body
$modal-inner-padding:               3rem !default;
$modal-content-border-width:        0;

$modal-content-border-radius:       $border-radius-big;

$modal-header-padding-y:            2rem;
$modal-header-padding-x:            3rem;

$modal-xl:                          1140px !default;
$modal-lg:                          690px;

// modal
$modal-backdrop-opacity:            1;
$modal-backdrop-bg:                 $primary;
$modal-header-padding:              11rem 10rem 6rem;
$modal-header-border-width:         0;
$modal-content-border-radius:       6px;
$modal-inner-padding:               0 10rem 8rem;

// dropdown
$dropdown-padding-y:                0;
$dropdown-spacer:                   0;
$dropdown-font-size:                1.6rem;
$dropdown-color:                    $black;
$dropdown-bg:                       $white;
$dropdown-border-color:             transparent;
$dropdown-border-radius:            0.2rem;
$dropdown-box-shadow:               0px 3px 30px rgba(46, 61, 121, 0.28);
$dropdown-item-padding-y:           1.6rem;
$dropdown-item-padding-x:           2.4rem;
$dropdown-link-color:               $black;
$dropdown-link-hover-color:         $black;
$dropdown-link-hover-bg:            $blue-05;
$dropdown-link-active-color:        $blue-02;
$dropdown-link-active-bg:           $blue-05;

// breadcrumb
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-margin-bottom:          3rem;
$breadcrumb-font-size:              1.4rem;
$breadcrumb-bg:                     transparent;
$breadcrumb-border-radius:          0;
$breadcrumb-active-color:           $grey-middle-dark;
$breadcrumb-divider-color:          $grey-middle-dark;

// button
$btn-padding-y:               0.8rem;
$btn-padding-x:               3rem;
$btn-font-family:             $font-family-sans-serif;
$btn-font-size:               1.6rem;
$btn-line-height:             1.5;
$btn-border-radius:           2.4rem;


// Navs

$nav-link-padding-y:                2rem;
$nav-link-padding-x:                1.2rem;
$nav-tabs-border-color:             #eee;
$nav-tabs-border-width:             0.4rem !default;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        #000;
$nav-tabs-link-active-bg:           transparent;
$nav-tabs-link-active-border-color: transparent transparent $primary !default;

