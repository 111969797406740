.owl-prev, .owl-next {
  display: none!important;
}
.carousel-courses {
  position: relative;
  > span {
    display: block!important;
    height: 40px;
    width: 40px;
    border: 1px solid #D5DCEB;
    border-radius: 50%;
    background-color: #F2F5FE;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(/img/arrow-next.svg);
    position: absolute;
    top: 50%;
    right: -5px;
    margin-top: -20px;
    cursor: pointer;
    z-index: 100;
    &:last-child {
      left: -5px;
      transform: rotate(180deg);
    }
  }
}
.carousel-calendar {
  > span {
    display: none!important;
  }
}