/**
 * button
 */
.btn {
  &_title-next {
    padding-right: 12rem;
    background-image: url("/img/arrow-next-white.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 3rem) 50%;
  }

  &_round-next {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    padding: 0;
    background: url("/img/arrow-next-round.svg") no-repeat 50% 50%;
  }
}