/**
 * carousel-courses.scss
 */
.carousel-courses {
  .item {
    height: 100%;
    margin: 0 15px;
  }

  .owl-stage {
    //display: flex;
  }

  &__item {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $blue-04;
    padding: 0 2.4rem 2.4rem;
    //margin-right: 1px;

    &-img {
      height: 14rem;
      margin: 0 -2.4rem;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    &-type {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
      padding: 0.8rem;
      border-radius: 6rem;
      background: rgba($primary, 0.1);
      text-align: center;
      font-size: 1.2rem;
      color: $blue-02;
    }

    &-title {
      //flex: 1;
      margin-bottom: 2.4rem;
      color: $black;
      height: 4.8rem;
      overflow: hidden;
    }

    &-link {
      text-decoration: none;
      font-size: 1.2rem;
      color: $blue-02;
      padding-right: 1.8rem;
      background: url('/img/arrow-next.svg') no-repeat 100% 50%;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .owl-carousel {
    .owl-dots {
      margin-top: 2.5rem!important;
      display: flex!important;
      flex-flow: wrap!important;
      justify-content: center!important;
    }

    .owl-dot {
      display: inline-block!important;
      margin: 0 0.4rem!important;

      &:focus,
      &:active {
        box-shadow: none!important;
        outline: 0!important;
      }

      span {
        display: block!important;
        height: 0.2rem!important;
        width: 5.2rem!important;
        background: $blue-04!important;
        margin: 0.5rem 0!important;
      }

      &.active span {
        background: $primary!important;
      }
    }
  }
}
