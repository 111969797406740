/**
 * dropdown
 */
.dropdown {

  &-menu {
    transform: none !important;
    top: 100% !important;
    left: -250%!important;
    box-shadow: $dropdown-box-shadow;
  }
}