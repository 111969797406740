/**
 * modal
 */
.modal {
  &-header {
    text-align: center;
    position: relative;
    justify-content: center;

    @media screen and (max-width: 990px) {
      padding: 4rem 6rem 3rem;
    }

    .close {
      padding: 1rem 1rem;
      margin: 0;
      font-size: 8.5rem;
      font-weight: 400;
      color: $white;
      text-shadow: none;
      opacity: 1;
      position: absolute;
      top: -6rem;
      right: 0;
      height: 4rem;
      width: 3rem;
      overflow: hidden;
      text-shadow: none;
      outline: 0;

      span {
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-title {    
    text-align: center;

    &_sm {
      text-transform: none;
      margin-bottom: -2rem;
    }
    
    @media screen and (max-width: 990px) {
      font-size: 3.3rem;
    }
  }

  &-body {    
    padding: 0 10rem 8rem;

    @media screen and (max-width: 990px) {
      padding: 0 6rem 5rem;
    }

    .block-ok {
      font-size: 1.6rem;
    }

    a.content_green {
      font-weight: 500;
    }
  }

  &-subheader {
    margin-bottom: 8rem;
    margin-top: -3rem;
    font-size: 1.6rem;

    @media screen and (max-width: 990px) {
      margin-top: 0;
    }
  }


}