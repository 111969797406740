/**
 * row table doc
 */
.row-table-doc {
  display: flex;
  padding: 1.8rem 0;
  border-bottom: 1px solid $blue-04;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:last-child {
    border-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &__col {
    padding: 0 3rem;
  }
  &__type {
    @media (max-width: 540px) {
      display: none;
    }
  }

  &__title {
    flex: 1;
    padding-left: 0;
    border-right: 1px solid $blue-04;

    &-text {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.3rem;
    }

    &-type {
      color: $black-03;
    }
  }

  &__duration {
    width: 10rem;

    span {
      display: block;
      position: relative;
      padding-left: 2.8rem;

      &:before {
        position: absolute;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        background: url('/img/icon-clock-blue.svg') no-repeat 0 0;
        background-size: contain;
        top: 50%;
        transform: translateY(-50%);
        content: ''
      }
    }

    &_volume {
      span {

        &:before {
          width: 1.2rem;
          background: url('/img/icon-doc.svg') no-repeat 0 0;
          background-size: contain;
        }
      }
    }
  }
}