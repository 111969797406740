/**
 * test-item.scss
 */
.test {
  &-item {

  }

  &-name {
    font-size: 2.4rem;
    line-height: 1.3;
  }

  &-data {
    span {
      display: block;
      padding-left: 3.8rem;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        top: 0;
        left: 0;
        background-position: 0 0;
        background-repeat: no-repeat;
      }
    }

    &__duration span:before {
      background-image: url("/img/icon-time.svg");
    }

    &__questions span:before {
      background-image: url("/img/icon-ok.svg");
    }

    &__reworks span:before {
      background-image: url("/img/icon-attention.svg");
    }
  }

  &-preamula {
    margin-bottom: 9rem;

    p {
      line-height: 1.5;
      margin-bottom: 0;
    }
  }

  &-ok {
    margin-top: 13rem;

    img {
      width: 7.2rem;
      height: 7.2rem;
    }
  }

  &-final {
    font-size: 2rem;
  }

  &-result {
    font-size: 2rem;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
}