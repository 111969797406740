/**
 * typography
 */

h1 {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 300;
  margin: 0;
}

h2 {
  font-size: 3rem;
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
}

h3 {
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 400;
  margin: 0;
}

.content-medium {
  font-weight: 500;
}

.content_12 {
  font-size: 1.2rem;
}

.content_14 {
  font-size: 1.4rem;
}

.content_18 {
  font-size: 1.8rem;
}

.content_20 {
  font-size: 2rem;
}

.content_22 {
  font-size: 2.2rem;
}

.content_40 {
  font-size: 4rem;
}