/**
 * layout
 */
html {
  font-size: 62.5%;
  max-width: 1920px;
  @media screen and (max-width: 1200px) {
    font-size: 53%;
  }
}

body {
  font-size: 1.6rem;
  max-width: 1920px;
}

.wrapper {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

// main
.main {
  flex: 1;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  padding-top: 8rem;
  width: 100%;
}

.aside {
  flex: 0 0 24rem;
  max-width: 24rem;
  background-image: url('/img/menuBackground.png');
  background-repeat: repeat-y;
  background-attachment: fixed !important;
  position: static !important;
  padding: 2.8rem;
  color: $white;

  @media (max-width: 1365px){
    display: none;
  }

  nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin-bottom: 32px;

      &.active {
        a {
          font-weight: 700;
        }
      }
    }
    a {
      font-family: "Roboto",sans-serif;
      display: inline-block;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: .4px;
      color: #fff;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      background-color: transparent;
      transition: all .2s ease-out;
    }
  }

  &__support {
    padding: 1.2rem;
    margin-top: 1rem;
    border: 1px solid $sidebar-section-border;
    color: $white;
    background: $sidebar-section;

    &-label {
      margin-bottom: 1.8rem;
      font-size: 1.4rem;
      font-weight: 500;
    }

    &-data {
      font-size: 1.2rem;
    }
  }
}

.content {
  padding: 2rem 6rem;
  flex: 0 0 calc(100% - 24rem);
  max-width: calc(100% - 24rem);

  @media (max-width: 1365px){
    padding: 2rem 3.2rem;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// header
.header {
  align-items: center;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  box-shadow: 0 2px 30px rgba(46, 61, 121, 0.13);

  &__wrapper {
    height: 8rem;
    display: flex;
    padding: 1rem 2.5rem;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1365px) {
      padding: 1rem 3.2rem;
    }
  }

  &__logo {
    flex: 0 0 7.7rem;
    max-width: 7.7rem;
    margin-right: 2rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__logo-ec {
    flex: 0 0 13.1rem;
    max-width: 13.1rem;
    margin-right: 5rem;

    img {
      width: 100%;
      height: auto;
    }
  }


  &__navigation {
    flex: 1;

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;


      li {
        margin: 0 2rem 0 0;
        font-size: 1.6rem;
        list-style: none;
        padding: 0;

        @media (max-width: 1400px){
          &:not(:first-child):not(:nth-child(2)):not(:last-child){
            display: none;
          }
        }

        /*@media (min-width: 1366px){
          &.dropdown {
            display: none;
          }
        }

        @media (max-width: 1365px){
          &.dropdown {
            display: block !important;
          }
        }*/
      }
    }

    .dropdown-toggle {
      width: 3.4rem;
      height: 3.4rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        display: none;
      }

      &-menu__dots {
        line-height: 1;
        font-size: 2rem;
      }
    }

    a {
      display: block;
      position: relative;
      color: $black;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        color: $primary;
      }
    }

    &-search {
      width: 1.6rem;
      height: 1.6rem;
      background: url('/img/icon-search.svg');
    }
  }

  &__question {
    margin-left: 5rem;
    margin-right: 2rem;

    @media (max-width: 1365px){
      margin-left: 2rem;
    }

    .button_secondary {
      width: 3.4rem;
      height: 3.4rem;
      display: block;
      border-radius: 50%;
      border: 0;
      background-color: rgba(65, 81, 162, 0.2);
      background-image: url("/img/icon-question.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      outline: none;

      &:not(:disabled):not(.disabled):active {
        box-shadow: none;
      }
    }
  }

  &__user {
    border-left: 4px solid rgba($blue-01, 0.1);
    padding: 0.8rem 0 0.8rem 2rem;

    .show > .btn-secondary.dropdown-toggle {
      background: transparent;
      box-shadow: none;
    }

    .btn {
      background: transparent;
      padding: 0;
      border: 0;
      display: flex;
      align-items: center;

      &:not(:disabled):not(.disabled):active {
        background: transparent;
        box-shadow: none;
      }

      &:after {
        margin-left: 1.255em;
        vertical-align: .255em;
        content: "";
        border: 0;
        width: 1.2rem;
        height: 0.6rem;
        background: url('/img/arrow-down.svg') no-repeat;
        background-size: contain;
      }
    }

    &-wrapper {
      width: 3.4rem;
      height: 3.4rem;
      border-radius: 50%;
      display: block;
      text-indent: -10000px;
      background: $blue-01 url("/img/icon-user-white.svg") no-repeat 50% 50%;
    }
  }
}

// hide pad
.toggle-menu {
  display: none;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
  }
}

// mobile navigation
.mobile-menu {
  //display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #ccc no-repeat 50% 0 / cover;
  color: $white;
  transition: all linear .3s;
  transform: translateX(100%);
  overflow: auto;

  .openned & {
    display: flex;
    flex-direction: column;
    transform: translateX(0);
  }

  nav {
    padding-top: 20rem;
    flex: 1;

    ul {
      display: inline-block;
      margin: 0;
      padding: 0;

      li {
        list-style: none;

        &:not(:last-child) {
          border-bottom: 1px solid rgba($white, 0.5);
        }
      }

      &.phone-show {
        display: none;

        @media (max-width: 450px) {
          display: block;
        }
      }

      a {
        padding: 2rem 0;
        display: block;
        font-size: 2.4rem;
        font-weight: 800;
        color: $white;
        text-transform: uppercase;

        @media (max-width: 540px) {
          padding: 1.5rem 0;
          display: block;
          font-size: 2rem;
        }

        @media (max-width: 350px) {
          padding: 1rem 0;
          font-size: 1.8rem;
        }

        @media (max-height: 540px) and (orientation: landscape) {
          padding: 1rem 0;
          font-size: 2rem;
        }
      }
    }
  }

  &__footer {
    padding: 2.5rem;
    font-size: 2rem;

    @media (max-width: 540px) {
      padding: 2.5rem 1.5rem;
      font-size: 1.7rem;
    }

    @media (max-height: 540px) and (orientation: landscape) {
      font-size: 1.8rem;
    }

    &-social {
      margin-bottom: 5rem;

      @media (max-width: 540px) {
        margin-bottom: 3rem;

        img {
          width: 4rem;
          height: 4rem;
        }
      }
    }

    &-copyright-links {
      max-width: 54rem;
      margin: 0 auto;

      @media (max-width: 540px) {
        font-size: 1.35rem;
      }
    }

    a {
      color: $white;
    }
  }
}

// footer
.footer {

  .row {
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    align-items: stretch;
  }

  &__spoiler {
    flex: 0 0 24rem;
    max-width: 24rem;
    background-image: url('/img/menuBackground.png');
    background-repeat: repeat-y;
    background-attachment: fixed !important;
    position: static !important;

    @media (max-width: 1365px) {
      display: none;
    }
  }

  &__content {
    flex: 1;

    &-menu {
      display: flex;
    }

    &-info {
      margin: 3rem 3.2rem 2.3rem;
      padding-top: 3.5rem;
      border-top: 1px solid $grey-light;

      @media (max-width: 1024px){
        display: none;
      }
    }

    &-location {
      margin: 3rem 0 5rem;
      font-size: 1.6rem;
      color: $grey-middle;
      max-width: 30rem;
    }

    &-socials {
      display: flex;

      a {
        width: 1.6rem;
        height: 1.6rem;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;

        + a {
          margin-left: 3rem;
        }

        &.socials-fb {
          background-image: url("/img/icon-social-fb.svg");
          background-size: 0.7rem auto;
        }
        &.socials-vk {
          background-image: url("/img/icon-social-vk.svg");
          background-size: auto 1.1rem;
        }
        &.socials-ok {
          background-image: url("/img/icon-social-ok.svg");
        }
        &.socials-insta {
          background-image: url("/img/icon-social-insta.svg");
        }
        &.socials-twitter {
          background-image: url("/img/icon-social-twitter.svg");
        }
        &.socials-youtube {
          background-image: url("/img/icon-social-youtube.svg");
          background-size: 1.3rem auto;
        }
      }
    }

    &-nav {
      margin-left: 2rem;

      ul {
        margin: 0;
        list-style: none;
      }

      li {
        margin: 0 0 2.8rem;

        &:last-child {
          margin: 0;
        }
      }

      a {
        color: $black;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &__copyright {
    padding: 2.5rem 3.2rem 2.5rem;
    border-top: 1px solid $grey-light;

    &-logo {
      img {
        display: block;
        max-width: 100%;
      }
    }
    &-info {
      font-size: 1.4rem;
      color: $grey-dark;

      p {
        margin: 0;
        max-width: 57rem;
      }
    }
    &-text {
      font-size: 1.2rem;
      color: $grey-middle;
    }
  }

  &__logo,
  &__logo-ec {

    img {
      width: 100%;
      height: auto;
    }
  }
}


// button
.button {
  padding: 1.7rem 3rem;
  line-height: 1;
  border-radius: 6px;
  font-size: 2rem;
  font-weight: 800;
  box-shadow: none;
  border: 0;
  outline: 0;
  transition: color 0.3s linear;
  position: relative;
  text-transform: uppercase;
  z-index: 2;

  &_secondary {
    background: $white;
    color: $primary;

    &:hover {
      color: $body-color;
    }
  }

  &_bordered {
    border: 1px solid;
  }

  &_plain {
    background: $white;
    color: $body-color;

    &:hover {
      color: $body-color;
    }
  }

  &_filled {
    color: $white;
    background: $primary;

    &:hover {
      color: $white;;
    }
  }
}

// athom
.mb-8 {
  margin-bottom: 8rem;
}

.content_big {
  font-size: 2rem;
}

.content_regular {
  font-size: 1.6rem;
}

.content_300 {
  font-weight: 300;
}

.content_400 {
  font-weight: 400;
}

.content_500,
.content_medium {
  font-weight: 500;
}

.content_600 {
  font-weight: 600;
}

.content_success {
  color: $green-01;
}

.content_important {
  color: $red-01;
}

.content_underline,
a.content_underline {
  text-decoration: underline;
}

.content_dashed {
  border-bottom: 1px dashed;
}

.content_green,
a.content_green {
  color: #45cb85;
}