













































.dotted-btn {
  border: none;
  background: inherit;
  font-weight: 400;
  &:focus {
    box-shadow: none!important;
  }
}
.dropdown-menu a {
  min-width: 218px;
  padding: 14px 16px;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  color: black!important;
  &:hover {
    color: white!important;
    background-color: rgb(97, 119, 255);
  }
}
.dropdown-header-menu__dots {
  width: 30px;
  height: 30px;
  margin-left: 30px;
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
  }
}
