/**
 * seminar
 */
.seminar {

  &-wrapper {
    margin-bottom: 2.4rem;
    padding: 0 2.4rem 0 0;
    border: 1px solid $grey-light;
    @media (max-width: 980px) {
      padding: 0;
    }
  }

  &-teaser {
    padding: 2rem 15% 2rem 37%;
    position: relative;
    height: 300px;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @media (max-width: 980px) {
      padding-left: 10px;
      padding-right: 0;
      height: auto;
    }

    > * {
      position: relative;
    }

    &__body {
      display: flex;
      flex-flow: wrap;
    }

    &__date {
      font-size: 3rem;
    }

    &__time,
    &__type {
      font-size: 2rem;
    }

    &__title {
      flex: 0 0 100%;
      max-width: 100%;
      font-size: 3rem;
      line-height: 3.2rem;
      margin: 20px 0;
      @media (max-width: 980px) {
        font-size: 18px;
      }
    }

    &__location {
      margin-bottom: .8rem;
      padding: 0 5rem 0 0;
      background: url('/img/icon-location.svg') no-repeat 0 50%;
      @media (max-width: 980px) {
        margin-bottom: 60px;
      }
    }

    &__lead {
      padding: 0 2.2rem 0 3.2rem;
      width: 300%;
      margin-left: -100%;
      margin-top: 3rem;
      .icon {
        margin-bottom: -7px;
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url('/img/icon-user.svg') no-repeat 0 50%;
      }
      @media (max-width: 980px) {
        display: block;
        width: auto;
        margin: 0;
      }
    }

    &__author {
      float: right;
      width: 35%;
      text-align: right;
      @media (max-width: 980px) {
        width: 60%;
      }
    }
    button {
      position: absolute;
      bottom: 3.2rem;
      right: 0;
      font-size: 2rem;
      @media (max-width: 980px) {
        right: 14px;
      }
    }

    &__image {
      width: 35%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      @media (max-width: 980px) {
        display: none;
      }
    }

    .btn {
      align-self: end;
    }
  }
}