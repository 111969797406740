/**
 * file-info.scss
 */
.file-info {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__icon {
    margin-right: 1.6rem;
  }

  &__content {
    flex: 1;
  }

  &__name {
    color: $primary;
    line-height: 1;
  }

  &__details {
    color: $black-02;
  }
}