/**
 * calendar
 */
.seminars-datepicker {
  border: 1px solid $blue-02;
  box-sizing: border-box;
  box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04);
  min-height: 34rem;
  margin-bottom: 2.4rem;
  padding: 1.1rem;
  background: #fff;

  .datepicker {

    &-inline {
      width: 100%;
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 6px;

      th,
      td {
        width: 2.4rem;
        height: 2.4rem;
      }

      th.dow {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 1.2rem;
        padding-top: 2.5rem;
        padding-bottom: 1rem;
      }

      th.datepicker-switch {
        font-weight: 400;
      }

      th.next {
        text-indent: -10000px;
        background-image: url("/img/arrow-calendar-next.svg");
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }

      th.prev {
        text-indent: -10000px;
        background-image: url("/img/arrow-calendar-prev.svg");
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }

      tr td.day.active {
        background: $blue-05;
        box-shadow: 0 0 0 2px $primary inset;
        color: $black;
      }

      tr td.day.today {
        background: $primary;
        color: $white;
      }
    }
  }
}