/**
 * tabs
 */
.nav-tabs {
  .nav-link {
    font-size: 1.4rem;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}