/**
 * form-radio.scss
 */
.form-radio {
  margin: 0 0 .8rem;

  &__wrapper  {
    display: inline-block;
    position: relative;

    label {
      position: relative;
      padding: 0.3rem 0.8rem 0.3rem 3.2rem;
      margin: 0;
      display: block;

      &:before {
        width: 1.6rem;
        height: 1.6rem;
        content: '';
        position: absolute;
        left: 0.8rem;
        top: (1.6rem * 1.5 + 0.3rem - 1.6rem) / 2;
        border: 1px solid #C8D0E1;
        border-radius: 50%;
        box-shadow: 0 0 0 .2rem #D5DCEB inset;
        background: #C8D0E1;
      }
    }

    &_true {
      label {
        background: rgba($green-01, 0.1);
      }
    }

    &_false {
      label {
        background: rgba($red-01, 0.1);
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 5;

      &:checked + label:before {
        border: 1px solid $primary;
        box-shadow: 0 0 0 .2rem $white inset;
        background: $primary;
      }
    }
  }
}