/**
 * test-pager.scss
 */
.test-pager {
  display: flex;
  flex-flow: wrap;

  &__item {
    padding: .5rem 1.3rem;
    border-bottom: 1px solid;
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 2.5rem;
    text-decoration: none;
    color: $black;

    &:hover {
      text-decoration: none;
    }

    &_current {
      background: $grey;
      color: $primary;
    }
    &_complete {
      color: $green-01;
    }
    &_error {
      color: $red-01;
    }
  }
}